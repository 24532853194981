import 'lightgallery.js';
import 'lightgallery.js/src/sass/lightgallery.scss';


export default class Gallery {
	constructor(id) {
		this.el = document.querySelector(id);
		this.init();
	}
	init() {
		this.gallery = lightGallery(this.el, {
			closable: false,
			download: false,
		});
	}
}

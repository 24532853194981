import debounce from './utils/debounce';

export default class NavItems {
	constructor() {
		this.navItems = document.querySelectorAll('[data-js-navitem]');
		this.init();
	}
	init() {
		this.setItemWidths();

		window.addEventListener('hashchange', (e) => {
			this.changeItemStates();
		});

		window.addEventListener(
			'resize',
			debounce(() => {
				this.setItemWidths();
			}, 400)
		);
	}
	setItemWidths() {
		this.navItems.forEach((el) => {
			el.style.width = 'auto';
			el.classList.add('is-active');
			el.style.width = `${el.offsetWidth + 1}px`;
		});
		this.changeItemStates();
	}
	changeItemStates() {
		const locationHash = window.location.hash;

		this.navItems.forEach((el) => {
			const href = el.getAttribute('href');

			if (locationHash.startsWith(href)) {
				el.classList.add('is-active');
			} else {
				el.classList.remove('is-active');
			}
		});
	}
}

import Swiper from 'swiper';
import 'swiper/css/swiper.css';

export default class Slider {
	constructor(el) {
		this.el = el;
		this.init();
	}
	init() {
		this.swiper = new Swiper(this.el, {
			on: {
				touchStart: function() {
					this.el.classList.remove('swiper-transition-ended');
				},
				transitionStart: function() {
					this.el.classList.remove('swiper-transition-ended');
				},
				transitionEnd: function() {
					this.el.classList.add('swiper-transition-ended');
				}
			},
			hashNavigation: {
				watchState: true
			},
			parallax: true,
//			autoHeight: true,
//			setWrapperSize: true,
			speed: 1500,
			direction: 'vertical',
			slidesPerView: 1,
			spaceBetween: 0,
			mousewheel: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			}
		});
	}
}

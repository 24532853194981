import "core-js/stable";
import "regenerator-runtime/runtime";
import Slider from './Slider';
import Gallery from './Gallery';
import NavItems from './NavItems';
import ScrollBox from './ScrollBox';

const slider = new Slider('#main-slider');

const lightgallery = new Gallery('#lightgallery');
const lightgallery2 = new Gallery('.lightgallery2');
const lightgallery3 = new Gallery('.lightgallery3');

const navItems = new NavItems();

const scrollBoxes = document
	.querySelectorAll('[data-js-scrollbox]')
	.forEach((el) => {
		new ScrollBox(el);
	});

export default class ScrollBox {
	constructor(el) {
		this.el = el;
		this.boxEl = this.el.querySelector('[data-js-scrollbox-box]');
		this.triggerEl = this.el.querySelector('[data-js-scrollbox-trigger]');
		this.triggerImg = this.el.querySelector('[data-js-scrollbox-img]');
		console.log(this.triggerImg);
		this.isScrolling = false;
		this.init();
	}
	init() {
		if (!this.triggerEl || !this.boxEl) {
			return null;
		}

		this.triggerEl.addEventListener('click', (e) => {
			if (this.isScrolling) {
				return;
			}
			if (this.boxEl.scrollTop < 50) {
				this.isScrolling = true;
				this.triggerImg.style.transform = 'rotate(180deg)';
				this.scrollTo(this.boxEl, this.boxEl.scrollHeight - this.boxEl.clientHeight, 5000);
			} else {
				this.isScrolling = true;
				this.triggerImg.style.transform = 'rotate(0deg)';
				this.scrollTo(this.boxEl, 0, 5000);
			}

		});
	}
	scrollTo(element, to, duration) {
		const start = element.scrollTop;
		const change = to - start;
		let currentTime = 0;
		const increment = 10;
		const self = this;

		const animateScroll = function() {
			currentTime += increment;
			var val = self.easeOutQuad(currentTime, start, change, duration);
			element.scrollTop = val;
			if(currentTime < duration) {
				setTimeout(animateScroll, increment);
			} else {
				self.isScrolling = false;
			}
		};
		animateScroll();
	}
	easeOutQuad (t, b, c, d) {
		return -c *(t/=d)*(t-2) + b;
	}
}
